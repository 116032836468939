export const saveSessionUserData = (userData: any) => {
  localStorage.setItem('user', JSON.stringify(userData))
}

export const saveSessionToken = ({ token, expires }: any) => {
  localStorage.setItem('token', token)
  localStorage.setItem('tokenExpiry', expires)
}

export const removeSessionData = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('tokenExpiry')
}

export const getUserData = () => {
  let userProfile

  try {
    userProfile = JSON.parse(localStorage.getItem('user') || '')
  } catch (e) {}
  return userProfile
}

export const getAuthToken = () => {
  return localStorage.getItem('token')
}

import React, { PureComponent } from 'react'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import { IInputProps, IInputState } from './interfaces'
import Label from '../Label'

class Input extends PureComponent<IInputProps, IInputState> {
  state = {
    showPassword: false,
  }

  /*======================= HANDLER METHODS ========================*/

  onClickShowPassword = () => {
    this.setState(({ showPassword }) => ({ showPassword: !showPassword }))
  }

  /*======================= RENDER METHODS ========================*/

  renderEndAdornment = () => {
    const { showPassword } = this.state
    const { endAdornment } = this.props

    return (
      <InputAdornment position="end">
        {endAdornment ? (
          endAdornment
        ) : (
          <IconButton
            edge="end"
            aria-label="Toggle password visibility"
            onClick={this.onClickShowPassword}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )}
      </InputAdornment>
    )
  }

  render() {
    const {
      id,
      type,
      label,
      classes,
      required,
      endAdornment,
      startAdornment,
      margin = 'normal',
      input,
      helperText,
      meta: { touched, error },
      ...rest
    } = this.props
    const { showPassword } = this.state
    const helper = !!(touched && error) ? error : helperText
    const inputType = showPassword ? 'text' : type

    return (
      <div className={classes.root}>
        {type === 'date' && label && (
          <Label
            htmlFor={id}
            required={required}
            label={label}
            error={!!(touched && error)}
          />
        )}
        <TextField
          id={id}
          fullWidth
          error={!!(touched && error)}
          margin={margin}
          helperText={helper}
          type={inputType}
          InputProps={{
            endAdornment:
              (type === 'password' || endAdornment) &&
              this.renderEndAdornment(),
            startAdornment,
          }}
          {...(type !== 'date' && { label, required })}
          {...input}
          {...rest}
        />
      </div>
    )
  }
}

export default Input

import React from 'react'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import Routes from 'routes'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const Root = ({ history, store }: any) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  </MuiPickersUtilsProvider>
)

export default hot(module)(Root)

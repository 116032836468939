import * as I from './interfaces'
import t from './types'

interface IActionHandler {
  readonly [r: string]: (
    state: I.IApplicationState,
    action: I.ApplicationInterfaces
  ) => I.IApplicationState
}

const initialState = {
  isActive: true,
  isOnline: true,
  notification: '',
  temp: {},
}

const ACTION_HANDLERS: IActionHandler = {
  [t.TAB_ACTIVE]: (state: I.IApplicationState, { active }: I.ITabActive) => ({
    ...state,
    isActive: active,
  }),
  [t.SET_TEMP_VALUE]: (
    state: I.IApplicationState,
    { field, value }: I.ISetTempValue
  ) => ({
    ...state,
    temp: {
      ...state.temp,
      [field]: value,
    },
  }),
  [t.ONLINE]: (state: I.IApplicationState) => ({
    ...state,
    isOnline: true,
  }),
  [t.OFFLINE]: (state: I.IApplicationState) => ({
    ...state,
    isOnline: false,
  }),
  [t.NOTIFICATION]: (
    state: I.IApplicationState,
    { notification }: I.INotification
  ) => ({
    ...state,
    notification,
  }),
  [t.CLEAR_NOTIFICATION]: (state: I.IApplicationState) => ({
    ...state,
    notification: '',
  }),
}

export default function reducer(
  state = initialState,
  action: I.ApplicationInterfaces
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

import ErrorBoundary from 'components/ErrorBoundary'
import ComponentLoader from 'components/Loaders/AsyncComponent'
import Notification from 'containers/notification'
import React, { lazy, PureComponent, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import WithAuthorization from 'routes/Helpers'
import { RedirectIf } from 'routes/RedirectIf'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import GlobalStyle from './style'
import theme from './theme'

const AuthtRoute = lazy(() => import('routes/Auth'))
const Dashboard = lazy(() => import('views/Dashboard'))

class Routes extends PureComponent {
  render() {
    return (
      <Router>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Container
            maxWidth="xl"
            disableGutters
            style={{ position: 'relative' }}
          >
            <Notification />
            <ErrorBoundary>
              <Suspense fallback={<ComponentLoader />}>
                <Switch>
                  <Route path="/dashboard">
                    {({ location }) => (
                      <WithAuthorization>
                        {(isAuthorized) => (
                          <RedirectIf
                            condition={!isAuthorized}
                            to={{
                              pathname: '/login',
                              search: '?redirect=' + location.pathname,
                            }}
                          >
                            <Route component={Dashboard} />
                          </RedirectIf>
                        )}
                      </WithAuthorization>
                    )}
                  </Route>
                  <Route component={AuthtRoute} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Container>
        </ThemeProvider>
      </Router>
    )
  }
}

export default Routes

import { Theme, withStyles } from '@material-ui/core/styles'
import composeGenericStyles from './composeGenerics'

const bg1Image = require('assets/images/bg-1.png')

const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    ...composeGenericStyles(theme),
    '.MuiButton-root': {
      '&.large': {
        padding: theme.spacing(2, 3.5),
      },
    },
    '.MuiButton-label': {
      textTransform: 'capitalize',
    },
    '.MuiButton-outlinedPrimary': {
      '&.rise-contained': {
        borderColor: 'rgba(229, 236, 240, 0.53)',
        backgroundColor: 'rgba(229, 236, 240, 0.53)',
      },
    },
    '.MuiPickersBasePicker-container': {
      paddingRight: 20,
      paddingLeft: 20,
    },
    '.MuiPickersCalendarHeader-switchHeader, .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '.MuiPickersCalendarHeader-iconButton': {
      background: 'rgba(229, 236, 240, 0.53)',
      color: theme.palette.primary.main,
      width: 29,
      height: 28,
      borderRadius: 2,
    },
    '.MuiPickersDay-daySelected': {
      width: 29,
      height: 28,
      borderRadius: 2,
    },
    '.tab-header-40': {
      '& .MuiTabs-scroller': {
        height: 40,
        '& .MuiTab-root': {
          minHeight: 40,
          height: '100%',
        },
      },
    },
    '.Bg-1': {
      zIndex: 0,
      minHeight: '100vh',
      position: 'relative',
      backgroundSize: '360px 919px',
      backgroundAttachment: 'fixed',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${bg1Image})`,
    },
    '.Flex': {
      display: 'flex',
      '&.end': {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
      '&.center': {
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&.justify-between': {
        justifyContent: 'space-between',
      },
      '&.justify-around': {
        justifyContent: 'space-around',
      },
      '&.justify-end': {
        justifyContent: 'flex-end',
      },
      '&.align-center': {
        alignItems: 'center',
      },
      '&.align-end': {
        alignItems: 'flex-end',
      },
      '&.column': {
        flexDirection: 'column',
      },
    },
    '.Capitalize': {
      textTransform: 'capitalize',
    },
    '.Pointer': {
      cursor: 'pointer',
    },
    '.Relative': {
      position: 'relative',
    },
    '.Ellipsis': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
    '.Hidden': {
      visibility: 'hidden',
    },
    '.caption1': {
      fontSize: '10px',
      lineHeight: '13px',
      color: '#17223C',
      opacity: 0.6,
      textTransform: 'uppercase',
    },
    '.title1': {
      fontSize: '15px',
      lineHeight: '22px',
      color: '#4f4f4f',
      fontWeight: '500',
    },
    '.title2': {
      color: '#ADADAD',
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: '500',
    },
    '.custom-iconButton': {
      background: 'white',
      boxShadow: '0px 9.76991px 20.354px -3.25664px rgba(17, 63, 99, 0.15)',
      '&.large': {
        width: '60px',
        height: '60px',
      },
      '&.medium': {
        width: '48px',
        height: '48px',
      },
      '&:hover': {
        background: 'rgba(255,255,255,.9)',
      },
    },
  },
}))(() => null)

export default GlobalCss

import * as I from './interfaces'
import types from './types'

export function setNotification(notification: string): I.INotification {
  return {
    notification,
    type: types.NOTIFICATION,
  }
}

export function clearNotification() {
  return { type: types.CLEAR_NOTIFICATION }
}

export function setTempValue(field: string, value: any): I.ISetTempValue {
  return {
    field,
    value,
    type: types.SET_TEMP_VALUE,
  }
}

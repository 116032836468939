import { IRootReducer } from 'redux/rootReducer'
import { createSelector } from 'reselect'

export const isLoading = (state: IRootReducer) => state.feed.isLoading
export const getActiveFeed = (state: IRootReducer) => state.feed.activeFeed
export const getLoadingFeeds = (state: IRootReducer) => state.feed.loadingFeeds

export const getFeedsObject = (state: IRootReducer) => state.feed.feeds
export const getFeeds = createSelector(getFeedsObject, (feedsObj) => {
  if (feedsObj) {
    return feedsObj.data
  }
  return feedsObj
})

import { constants } from 'config'
import { fetchRequest } from './rest'

export function login(email: string, password: string) {
  return fetchRequest('/auth/login', 'post', { email, password })
}

export function createAdminUser(formData: IUserForm) {
  return fetchRequest('/users/new', 'post', formData)
}

export function createUser(formData: IUserForm) {
  return fetchRequest('/users', 'post', formData)
}

export function forgotPassword(email: string) {
  return fetchRequest('/auth/reset-password', 'get', { email })
}

export function resetPasswordOtp(otp: string) {
  return fetchRequest(`/auth/reset/${otp}`, 'get')
}

export function setNewPassword(password: string) {
  return fetchRequest('/auth/reset-password', 'post', { password })
}

export function getDetails(params?: any) {
  return fetchRequest('/auth/profile', 'get', params)
}

/** ============================ USER API ================================ **/

export function getUser(id: string | number, params?: any) {
  return fetchRequest(`/users/${id}`, 'get', params)
}

export function updateUser(id: string | number, data?: IUserUpdateForm) {
  return fetchRequest(`/users/${id}`, 'put', data)
}

export function deleteUser(userId: number | string) {
  return fetchRequest(`/users/${userId}`, 'delete')
}

export function getWallet(userId: number | string) {
  return fetchRequest(`/users/${userId}/get-wallet`, 'get')
}

export function getStats(params: { userId: string | number }) {
  return fetchRequest('/auth/stats', 'get', params)
}

export function getUsers(params?: any) {
  return fetchRequest('/users/stats', 'get', params)
}

export function getDocuments(userId: string | number) {
  return fetchRequest(`/users/${userId}/documents`, 'get')
}

export function approveDocument(id: number) {
  return fetchRequest(`/documents/${id}/approve`, 'post')
}

export function addDocument(userId: string, data: FormData) {
  return fetchRequest(
    `${constants.requestConfig.v2baseURL}/users/${userId}/documents`,
    'post',
    data,
    {
      'Content-Type': 'multipart/form-data',
    }
  )
}

export function createUserPlan(data: IUserPlanForm) {
  return fetchRequest('/user-plans', 'post', data)
}

export function getPlans(params: any) {
  return fetchRequest('/user-plans', 'get', params)
}

export function updatePlan(planId: number, data: IUpdatePlanForm) {
  return fetchRequest(`/user-plans/${planId}`, 'put', data)
}

/** ============================ PLANS API ================================ **/

export function getInvestmentPlans() {
  return fetchRequest('/investment-plans', 'get')
}

export function transferFromPlanToWallet({
  amount,
  planId,
  userId,
}: {
  amount: number
  planId: number
  userId: number
}) {
  return fetchRequest('/transactions/withdraw', 'post', {
    from: 'plan',
    to: 'wallet',
    userPlanId: planId,
    userId,
    amount,
  })
}

/** ============================ TRANSACTIONS API ================================ **/

export function getTransactions(params?: any) {
  return fetchRequest('/transactions', 'get', params)
}

export function getTransaction(id: number | string, params?: any) {
  return fetchRequest(`/transactions/${id}`, 'get', params)
}

export function approveTransaction(id: number | string) {
  return fetchRequest(`/admin/transactions/${id}/approve`, 'post')
}

export function declineTransaction(id: number | string) {
  return fetchRequest(`/admin/transactions/${id}/decline`, 'post')
}

export function batchApproveDeclineTransactions(
  data: IBatchApproveDeclineTransactionsForm
) {
  return fetchRequest(
    '/admin/transactions/withdrawals/batch-process',
    'post',
    data
  )
}

export function fund(data?: IFundWalletForm | IFundPlanForm) {
  return fetchRequest('/admin/transactions/create', 'post', data)
}

export function transferFromWalletToBank({
  amount,
  bankAccountId,
  userId,
}: {
  amount: number
  bankAccountId: string
  userId: number
}) {
  return fetchRequest('/transactions/withdraw', 'post', {
    from: 'wallet',
    to: 'bank-account',
    bankAccountId,
    userId,
    amount,
  })
}

/** ============================ FFEDS API ================================ **/

export function getFeeds(params?: any) {
  return fetchRequest('/feeds', 'get', params)
}

export function getFeed(feedId: number | string) {
  return fetchRequest(`/feeds/${feedId}`, 'get')
}

export function createFeed(data: IFeedForm) {
  return fetchRequest('/feeds', 'post', data)
}

export function updateFeed(feedId: number | string, data: IUpdateFeedForm) {
  return fetchRequest(`/feeds/${feedId}`, 'put', data)
}

export function deleteFeed(feedId: number | string) {
  return fetchRequest(`/feeds/${feedId}`, 'delete')
}

/** ============================ PERMISSIONS API ================================ **/

export function getPermissions() {
  return fetchRequest('/admin-permissions?$nopaginate=1', 'get')
}

/** ============================ GROUPS API ================================ **/

export function getAdminGroups() {
  return fetchRequest(
    '/admin-groups?$nopaginate=1&$include=admin_group_permission',
    'get'
  )
}

export function createAdminGroup(name: string, permissions: number[]) {
  return fetchRequest('/admin-groups', 'post', { name, permissions })
}

export function updateAdminGroup(
  groupId: number,
  name: string,
  permissions: number[]
) {
  return fetchRequest(`/admin-groups/${groupId}`, 'put', { name, permissions })
}

export function deleteAdminGroup(groupId: number) {
  return fetchRequest(`/admin-groups/${groupId}`, 'delete')
}

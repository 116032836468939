import { IRootReducer } from 'redux/rootReducer'
import { createSelector } from 'reselect'

export const getUser = (state: IRootReducer) => state.user.user
export const getActiveUser = (state: IRootReducer) => state.user.activeUser
export const getActiveAdminUser = (state: IRootReducer) =>
  state.user.activeAdminUser
export const getError = (state: IRootReducer) => state.user.error
export const isLoading = (state: IRootReducer) => state.user.isLoading
export const isSavingDocument = (state: IRootReducer) =>
  state.user.isSavingDocument
export const getLoadingUsers = (state: IRootReducer) => state.user.loadingUsers
export const getUpdatingPlanIds = (state: IRootReducer) =>
  state.user.updatingPlanIds
export const isLoadingActiveUser = (state: IRootReducer) =>
  state.user.isLoadingActiveUser
export const isLoadingActiveAdminUser = (state: IRootReducer) =>
  state.user.isLoadingActiveAdminUser
export const isApprovingDocument = (state: IRootReducer) =>
  state.user.isApprovingDocument
export const isAuthorized = (state: IRootReducer) => state.user.isAuthorized
export const getUsersObject = (state: IRootReducer) => state.user.users
export const getAdminUsersObject = (state: IRootReducer) =>
  state.user.adminUsers
export const getUsers = createSelector(getUsersObject, (usersObj) => {
  if (usersObj) {
    return usersObj.data
  }
  return usersObj
})
export const getAdminUsers = createSelector(
  getAdminUsersObject,
  (adminUsersObj) => {
    if (adminUsersObj) {
      return adminUsersObj.data
    }
    return adminUsersObj
  }
)

import { createStyles, makeStyles } from '@material-ui/core/styles'
import IPickerProps from './interfaces'

export default makeStyles((theme) =>
  createStyles({
    icon: {
      color: ({ disabled, meta: { touched, error, active } }: IPickerProps) =>
        !!(touched && error)
          ? theme.palette.error.main
          : disabled
          ? theme.palette.action.disabled
          : active
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
    },
    input: {
      width: '100%',
    },
    root: {
      width: '100%',
    },
  })
)

import React from 'react'
import { InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface ILabelProps {
  htmlFor: string
  label: string
  error?: boolean
  required?: boolean
  passedRef?: any
}

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: '600',
    fontSize: '.8rem',
  },
}))

const Label = ({ htmlFor, label, passedRef, required, error }: ILabelProps) => {
  const classes = useStyles()

  return (
    <InputLabel
      ref={passedRef}
      error={error}
      required={required}
      htmlFor={htmlFor}
      className={classes.root}
    >
      {label}
    </InputLabel>
  )
}

export default Label

import { getUserData, getAuthToken } from 'api/session'
import { IBatchApproveDeclineTransactionsSuccess } from 'redux/transaction/interfaces'
import transactionTypes from 'redux/transaction/types'
import * as I from './interfaces'
import t from './types'

interface IActionHandler {
  readonly [r: string]: (
    state: I.IUserState,
    action: I.UserInterfaces
  ) => I.IUserState
}

// Fix rootReducer error
const getActiveUser = (): any => null
const getUpdatingPlanIds = (): any => []

const initialState = {
  activeUser: getActiveUser(),
  activeAdminUser: getActiveUser(),
  error: '',
  loadingUsers: {},
  isApprovingDocument: false,
  isAuthorized: !!getAuthToken(),
  isLoading: false,
  isLoadingActiveUser: false,
  isLoadingActiveAdminUser: false,
  isSavingDocument: false,
  token: getAuthToken(),
  updatingPlanIds: getUpdatingPlanIds(),
  user: getUserData(),
}

const ACTION_HANDLERS: IActionHandler = {
  [t.LOGIN_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    error: '',
  }),
  [t.LOGIN_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
  }),
  [t.LOGIN_SUCCESS]: (state: I.IUserState): I.IUserState => {
    return {
      ...state,
      isAuthorized: true,
    }
  },
  [t.SET_USER_PROFILE]: (
    state: I.IUserState,
    { user }: I.ISetUser
  ): I.IUserState => ({
    ...state,
    user,
  }),
  [t.SET_AUTH_TOKEN]: (
    state: I.IUserState,
    { token }: I.ISetTokenRequest
  ): I.IUserState => ({
    ...state,
    token,
  }),
  [t.LOGOUT_SUCCESS]: (): I.IUserState => ({
    ...initialState,
    isAuthorized: false,
    token: null,
    user: null,
  }),
  [t.GET_USERS_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoading: true,
  }),
  [t.GET_USERS_SUCCESS]: (
    state: I.IUserState,
    { users }: I.IGetUsersSuccess
  ): I.IUserState => ({
    ...state,
    isLoading: false,
    users,
  }),
  [t.GET_USERS_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoading: false,
  }),
  [t.GET_USER_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    activeUser: null,
    isLoadingActiveUser: true,
  }),
  [t.GET_USER_SUCCESS]: (
    state: I.IUserState,
    { user: activeUser }: I.IGetUserSuccess
  ): I.IUserState => ({
    ...state,
    isLoadingActiveUser: false,
    activeUser,
  }),
  [t.GET_USER_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoadingActiveUser: false,
  }),
  [t.GET_ADMIN_USERS_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoading: true,
  }),
  [t.GET_ADMIN_USERS_SUCCESS]: (
    state: I.IUserState,
    { users }: I.IGetAdminUsersSuccess
  ): I.IUserState => ({
    ...state,
    isLoading: false,
    adminUsers: users,
  }),
  [t.GET_ADMIN_USERS_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoading: false,
  }),
  [t.GET_ADMIN_USER_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    activeAdminUser: null,
    isLoadingActiveAdminUser: true,
  }),
  [t.GET_ADMIN_USER_SUCCESS]: (
    state: I.IUserState,
    { user: activeAdminUser }: I.IGetAdminUserSuccess
  ): I.IUserState => ({
    ...state,
    isLoadingActiveAdminUser: false,
    activeAdminUser,
  }),
  [t.GET_ADMIN_USER_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isLoadingActiveAdminUser: false,
  }),
  [transactionTypes.BATCH_APPROVE_DECLINE_TRANSACTIONS_SUCCESS]: (
    state: I.IUserState,
    { transactionIds }: IBatchApproveDeclineTransactionsSuccess
  ) => ({
    ...state,
    activeUser: !state.activeUser
      ? state.activeUser
      : {
          ...state.activeUser,
          pendingWithdrawals: state.activeUser.pendingWithdrawals.filter(
            ({ id }) => !transactionIds.includes(id)
          ),
        },
  }),
  [t.UPDATE_PLAN_REQUEST]: (
    state: I.IUserState,
    { planId }: I.IUpdatePlanRequest
  ): I.IUserState => ({
    ...state,
    updatingPlanIds: [...state.updatingPlanIds, planId],
  }),
  [t.UPDATE_PLAN_SUCCESS]: (
    state: I.IUserState,
    { planId, data }: I.IUpdatePlanSuccess
  ): I.IUserState => ({
    ...state,
    updatingPlanIds: state.updatingPlanIds.filter((id) => id !== planId),
    activeUser: !state.activeUser
      ? state.activeUser
      : {
          ...state.activeUser,
          plans: state.activeUser.plans.map((plan) =>
            plan.id === planId ? { ...plan, ...data } : plan
          ),
        },
  }),
  [t.UPDATE_PLAN_ERROR]: (
    state: I.IUserState,
    { planId }: I.IUpdatePlanError
  ): I.IUserState => ({
    ...state,
    updatingPlanIds: state.updatingPlanIds.filter((id) => id !== planId),
  }),
  [t.SAVE_DOCUMENT_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isSavingDocument: true,
  }),
  [t.SAVE_DOCUMENT_SUCCESS]: (
    state: I.IUserState,
    { documents }: I.ISaveDocumentSuccess
  ): I.IUserState => ({
    ...state,
    isSavingDocument: false,
    ...(state.activeUser && {
      activeUser: {
        ...state.activeUser,
        documents,
      },
    }),
  }),
  [t.SAVE_DOCUMENT_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isSavingDocument: false,
  }),

  [t.APPROVE_DOCUMENT_REQUEST]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isApprovingDocument: true,
  }),
  [t.APPROVE_DOCUMENT_SUCCESS]: (
    state: I.IUserState,
    { id }: I.IApproveDocumentSuccess
  ): I.IUserState => {
    return {
      ...state,
      isApprovingDocument: false,
      ...(state.activeUser && {
        activeUser: {
          ...state.activeUser,
          documents: state.activeUser.documents.map((doc) =>
            doc.id === id
              ? {
                  ...doc,
                  status: 'approved',
                }
              : doc
          ),
        },
      }),
    }
  },
  [t.APPROVE_DOCUMENT_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    isApprovingDocument: false,
  }),

  [t.DELETE_USER_REQUEST]: (
    state: I.IUserState,
    { id }: I.IDeleteUserRequest
  ): I.IUserState => ({
    ...state,
    loadingUsers: {
      ...state.loadingUsers,
      [id]: true,
    },
  }),
  [t.DELETE_USER_SUCCESS]: (
    state: I.IUserState,
    { id: userId, isAdmin }: I.IDeleteUserSuccess
  ): I.IUserState => {
    const { data, meta } = isAdmin
      ? (state.adminUsers as IUserObject)
      : (state.users as IUserObject)
    const { total, offset } = meta
    const update = {
      data: data.filter(({ id }) => id !== userId),
      meta: {
        ...meta,
        total: total - 1,
        offset: !!offset ? offset - 1 : offset,
      },
    }

    return {
      ...state,
      loadingUsers: {
        ...state.loadingUsers,
        [userId]: false,
      },
      ...(isAdmin ? { adminUsers: update } : { users: update }),
    }
  },
  [t.DELETE_USER_ERROR]: (
    state: I.IUserState,
    { id }: I.IDeleteUserError
  ): I.IUserState => ({
    ...state,
    loadingUsers: {
      ...state.loadingUsers,
      [id]: false,
    },
  }),
  [t.ERROR]: (state: I.IUserState, { error }: I.IError) => ({
    ...state,
    error,
  }),
  [t.CLEAR_ERROR]: (state: I.IUserState): I.IUserState => ({
    ...state,
    error: '',
  }),
}

export default function reducer(
  state = initialState,
  action: I.UserInterfaces
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

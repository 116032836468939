import React, { Fragment } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { FormControl, FormHelperText, Select } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Label from '../Label'

type INativeProp = {
  helperText?: string
  id: string
  label: string
  name: string
  options: {
    label: string | number
    value: string | number
  }[]
  placeholder?: string
  required?: boolean
} & WrappedFieldProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
  })
)

export default function NativeSelects({
  helperText,
  id,
  label,
  options,
  placeholder,
  input,
  required,
  meta: { touched, error },
  ...rest
}: INativeProp) {
  const classes = useStyles()
  const helper = !!(touched && error) ? error : helperText

  return (
    <FormControl className={classes.formControl} error={!!(touched && error)}>
      <Label htmlFor={id} required={required} label={label} />
      <Select
        native
        inputProps={{
          id,
          ...input,
        }}
        {...rest}
      >
        <Fragment>
          {placeholder ? (
            <option disabled value="">
              {placeholder}
            </option>
          ) : (
            <option value="" />
          )}
          {options.map(({ label, value }) => (
            <option key={`${label}${value}`} value={value}>
              {label}
            </option>
          ))}
        </Fragment>
      </Select>
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  )
}

const asFormDetails = (
  name: string,
  fields: string[],
  required?: string[]
) => ({
  name,
  fields,
  required,
})

const passwordResetFields = ['email']
const verifyOtpFields = ['otp']
const loginFields = ['email', 'password']
const saveUserFields = ['email', 'firstName', 'lastName', 'phone', 'password']
const newPasswordFields = ['password', 'confirmPassword']
const createFeedFields = [
  'title',
  'description',
  'content',
  'thumbnail',
  'v2Thumbnail',
  'url',
]
const createGroupFields = ['name', 'permissions']

export const loginForm = asFormDetails('login', loginFields, loginFields)
export const saveUserForm = asFormDetails(
  'saveUser',
  saveUserFields,
  saveUserFields
)
export const verifyOtpForm = asFormDetails(
  'verifyOtp',
  verifyOtpFields,
  verifyOtpFields
)
export const customerPendingWithdrawalForm = asFormDetails(
  'customerPendingWithdrawal',
  [],
  []
)
export const customerDetailForm = asFormDetails(
  'customerDetail',
  ['email', 'bvn'],
  ['email', 'bvn']
)
export const passwordResetForm = asFormDetails(
  'passwordReset',
  passwordResetFields,
  passwordResetFields
)
export const newPasswordForm = asFormDetails(
  'newPassword',
  newPasswordFields,
  newPasswordFields
)
export const createFeedForm = asFormDetails(
  'createFeed',
  createFeedFields,
  createFeedFields
)
export const createGroupForm = asFormDetails(
  'createGroup',
  createGroupFields,
  createGroupFields
)

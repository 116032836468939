import { Theme, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  rise: {
    palette: {
      gradient: [
        'linear-gradient(270.45deg, #22D8E2 0.37%, #0898A0 81.51%)',
        'linear-gradient(278.97deg, #22D8E2 0.37%, #0898A0 81.51%)',
      ],
      grey: ['#f7f7fa'],
    },
  },
  palette: {
    primary: { light: '#58E8F0', main: '#0898A0', dark: '#0090B0' },
    secondary: { light: '#58E8F0', main: '#0898A0', dark: '#0090B0' },
    text: { primary: '#000000' },
  },
} as Theme)

export default theme

import { buffers, eventChannel } from 'redux-saga'
import { all, call, fork, put, take } from 'redux-saga/effects'
import t from './types'

function createOnlineOfflineChannel() {
  return eventChannel((emit) => {
    function emitOnline() {
      emit('online')
    }

    function emitOffline() {
      emit('offline')
    }

    window.addEventListener('online', emitOnline)
    window.addEventListener('offline', emitOffline)

    return () => {
      window.removeEventListener('online', emitOnline)
      window.removeEventListener('offline', emitOffline)
    }
  }, buffers.fixed())
}

function createTabActivityChannel() {
  return eventChannel((emit) => {
    function emitVisibility() {
      emit(!document.hidden)
    }

    window.addEventListener('visibilitychange', emitVisibility)

    return () => {
      window.removeEventListener('visibilitychange', emitVisibility)
    }
  }, buffers.fixed())
}

function* watchConnectionStatusSaga() {
  while (true) {
    const channel = yield call(createOnlineOfflineChannel)
    while (true) {
      const status = yield take(channel)
      switch (status) {
        case 'online':
          yield put({ type: t.ONLINE })
          break
        case 'offline':
          yield put({ type: t.OFFLINE })
          break
        default:
          // tslint:disable-next-line
          console.warn('Unknown status ', status)
      }
    }
  }
}

export function* watchTabActiveSaga() {
  const source = yield call(createTabActivityChannel)

  while (true) {
    const active = yield take(source)
    yield put({ type: t.TAB_ACTIVE, active })
  }
}

export default function* () {
  yield all([fork(watchTabActiveSaga), fork(watchConnectionStatusSaga)])
}

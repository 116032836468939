import { IRootReducer } from 'redux/rootReducer'
import { createSelector } from 'reselect'

export const isFunding = (state: IRootReducer) => state.transaction.isFunding
export const isLoading = (state: IRootReducer) => state.transaction.isLoading
export const getActiveTransaction = (state: IRootReducer) =>
  state.transaction.activeTransaction
export const isLoadingActiveTransaction = (state: IRootReducer) =>
  state.transaction.isLoadingActiveTransaction
export const isProcessingActiveTransaction = (state: IRootReducer) =>
  state.transaction.isProcessingActiveTransaction
export const getTransactionsObject = (state: IRootReducer) =>
  state.transaction.transactions
export const getTransactions = createSelector(
  getTransactionsObject,
  (transactionsObj) => {
    if (transactionsObj) {
      return transactionsObj.data
    }
    return transactionsObj
  }
)

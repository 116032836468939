import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    plain: {
      background: '#FFFFFF',
      border: '1px solid #E1E2E7',
      boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
      borderRadius: theme.spacing(0.5),
      color: '#0898A0',
      padding: theme.spacing(2, 8, 2, 8),
      textTransform: 'capitalize',
      fontWeight: '600',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: '#FFFFFF',
        boxShadow:
          '0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)',
      },
      '&:disabled': {
        opacity: 0.7,
        boxShadow: theme.shadows[10],
      },
    },
    primary: {
      background: '#0898A0',
      border: '1px solid rgba(34, 216, 226, 0.2)',
      boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
      borderRadius: theme.spacing(0.5),
      color: theme.palette.common.white,
      padding: theme.spacing(2, 8, 2, 8),
      textTransform: 'capitalize',
      fontWeight: '600',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: '#0898A0',
        boxShadow:
          '0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)',
      },
      '&:disabled': {
        opacity: 0.7,
        boxShadow: theme.shadows[10],
        color: theme.palette.common.white,
      },
    },
    root: {
      background: theme.rise.palette.gradient[1],
      boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
      borderRadius: theme.spacing(0.5),
      color: theme.palette.common.white,
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      textTransform: 'capitalize',
      fontWeight: '600',
      fontSize: '1rem',
      '&:hover': {
        boxShadow:
          '0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)',
      },
      '&:disabled': {
        opacity: 0.7,
        boxShadow: theme.shadows[10],
        color: theme.palette.common.white,
      },
    },
  })
)

export default useStyles

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import application from 'redux/application/reducer'
import { IApplicationState } from 'redux/application/interfaces'
import { IFeedState } from 'redux/feed/interfaces'
import feed from 'redux/feed/reducer'
import { ITransactionState } from 'redux/transaction/interfaces'
import transaction from 'redux/transaction/reducer'
import { IUserState } from 'redux/user/interfaces'
import user from 'redux/user/reducer'

export interface IRootReducer {
  application: IApplicationState
  feed: IFeedState
  form: {}
  transaction: ITransactionState
  user: IUserState
}

export default combineReducers<IRootReducer>({
  application,
  feed,
  form,
  transaction,
  user,
})

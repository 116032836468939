import { all, fork } from 'redux-saga/effects'
import applicationSagas from 'redux/application/sagas'
import errorSaga from 'redux/errorSaga'
import feedSagas from 'redux/feed/sagas'
import transactionSagas from 'redux/transaction/sagas'
import userSagas from 'redux/user/sagas'

export default function* root() {
  yield all([
    fork(errorSaga),
    fork(applicationSagas),
    fork(transactionSagas),
    fork(userSagas),
    fork(feedSagas),
  ])
}

enum Types {
  FUND_REQUEST = 'transaction/FUND_REQUEST',
  FUND_SUCCESS = 'transaction/FUND_SUCCESS',
  FUND_ERROR = 'transaction/FUND_ERROR',

  GET_TRANSACTIONS_REQUEST = 'transaction/GET_TRANSACTIONS_REQUEST',
  GET_TRANSACTIONS_SUCCESS = 'transaction/GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_ERROR = 'transaction/GET_TRANSACTIONS_ERROR',

  GET_TRANSACTION_DETAILS_REQUEST = 'transaction/GET_TRANSACTION_DETAILS_REQUEST',
  GET_TRANSACTION_DETAILS_SUCCESS = 'transaction/GET_TRANSACTION_DETAILS_SUCCESS',
  GET_TRANSACTION_DETAILS_ERROR = 'transaction/GET_TRANSACTION_DETAILS_ERROR',

  APPROVE_TRANSACTION_REQUEST = 'transaction/APPROVE_TRANSACTION_REQUEST',
  APPROVE_TRANSACTION_SUCCESS = 'transaction/APPROVE_TRANSACTION_SUCCESS',
  APPROVE_TRANSACTION_ERROR = 'transaction/APPROVE_TRANSACTION_ERROR',

  DECLINE_TRANSACTION_REQUEST = 'transaction/DECLINE_TRANSACTION_REQUEST',
  DECLINE_TRANSACTION_SUCCESS = 'transaction/DECLINE_TRANSACTION_SUCCESS',
  DECLINE_TRANSACTION_ERROR = 'transaction/DECLINE_TRANSACTION_ERROR',

  BATCH_APPROVE_DECLINE_TRANSACTIONS_REQUEST = 'transaction/BATCH_APPROVE_DECLINE_TRANSACTIONS_REQUEST',
  BATCH_APPROVE_DECLINE_TRANSACTIONS_SUCCESS = 'transaction/BATCH_APPROVE_DECLINE_TRANSACTIONS_SUCCESS',
  BATCH_APPROVE_DECLINE_TRANSACTIONS_ERROR = 'transaction/BATCH_APPROVE_DECLINE_TRANSACTIONS_ERROR',
}

export default Types

import { getAuthToken } from './session'
import { constants } from '../config'

const checkStatus = (parser: 'blob' | 'json') => async (response: any) => {
  if (response.ok) {
    const data = await response[parser]()

    return { data }
  } else {
    const error = await response[parser]()

    // eslint-disable-next-line
    throw {
      ...error,
      status: response.status,
    }
  }
}

const asKeyValue = (key: string, value: number) => {
  return encodeURIComponent(key) + '=' + encodeURIComponent(String(value))
}

const encodeQuery = (obj: any) => {
  const str = []
  for (const key of Object.keys(obj)) {
    const value = obj[key]
    if (Array.isArray(value)) {
      value.forEach((val) => str.push(asKeyValue(key, val)))
    } else {
      str.push(asKeyValue(key, value))
    }
  }
  return str.join('&')
}

const makeRestOptions = async (
  endpoint: string,
  method: IAPIMethods,
  body = {},
  headers: IAPIExtraHeader = {}
) => {
  const params: any = {
    headers,
    url: endpoint.startsWith('http')
      ? endpoint
      : constants.requestConfig.baseURL + endpoint,
    method,
  }

  const storedToken = await getAuthToken()
  const token = !!storedToken ? `Bearer ${storedToken}` : null

  if (token) {
    params.headers = { ...params.headers, Authorization: token }
  }

  let { url } = params

  if (Object.keys(body).length !== 0) {
    if (params.method === 'get') {
      url += '?' + encodeQuery(body)
    } else {
      params.body = JSON.stringify(body)
      params.headers['Content-Type'] = 'application/json'
    }
  } else if (params.headers['Content-Type'] === 'multipart/form-data') {
    delete params.headers['Content-Type']
    params.body = body
  }

  return { ...params, url }
}

export { checkStatus, makeRestOptions }

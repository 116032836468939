import React from 'react'
import { connect } from 'react-redux'
import { IRootReducer } from 'redux/rootReducer'
import { getUser } from 'redux/user/selectors'

interface InterfacePrivateComponent {
  isAuthorized: boolean
  children: (isAuthorized: boolean) => JSX.Element
}

const mapStateToProps = (state: IRootReducer) => ({
  isAuthorized: !!getUser(state),
})

const PrivateComponent: React.SFC<InterfacePrivateComponent> = ({
  isAuthorized,
  children,
}) => children(isAuthorized)

const WithAuthorization = connect(mapStateToProps)(PrivateComponent)

export default WithAuthorization

enum Types {
  GET_FEEDS_REQUEST = 'feed/GET_FEEDS_REQUEST',
  GET_FEEDS_SUCCESS = 'feed/GET_FEEDS_SUCCESS',
  GET_FEEDS_ERROR = 'feed/GET_FEEDS_ERROR',

  GET_FEED_REQUEST = 'feed/GET_FEED_REQUEST',
  GET_FEED_SUCCESS = 'feed/GET_FEED_SUCCESS',
  GET_FEED_ERROR = 'feed/GET_FEED_ERROR',

  SAVE_FEED_REQUEST = 'feed/SAVE_FEED_REQUEST',
  SAVE_FEED_SUCCESS = 'feed/SAVE_FEED_SUCCESS',
  SAVE_FEED_ERROR = 'feed/SAVE_FEED_ERROR',

  DELETE_FEED_REQUEST = 'feed/DELETE_FEED_REQUEST',
  DELETE_FEED_SUCCESS = 'feed/DELETE_FEED_SUCCESS',
  DELETE_FEED_ERROR = 'feed/DELETE_FEED_ERROR',
}

export default Types

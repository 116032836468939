enum Types {
  TAB_ACTIVE = 'application/TAB_ACTIVE',

  NOTIFICATION = 'application/NOTIFICATION',
  CLEAR_NOTIFICATION = 'application/NOTIFICATION_CLEAR',

  SET_TEMP_VALUE = 'application/SET_TEMP_VALUE',

  ONLINE = 'application/ONLINE',
  OFFLINE = 'application/OFFLINE',
}

export default Types

export default {
  DEFAULT_ERROR_MESSAGE: 'Something went wrong, Try reloading the page',
  requestConfig: {
    baseURL:
      process.env.NODE_ENV === 'production'
        ? 'https://app.rise.capital/api'
        : process.env.BASEURL ||
          'https://manator-staging-qogza.ondigitalocean.app/api/v1',
    v2baseURL:
      process.env.NODE_ENV === 'production'
        ? 'https://app.rise.capital/api/v2'
        : process.env.BASEURL ||
          'https://manator-staging-qogza.ondigitalocean.app/api/v2',
    timeout: 180000,
  },
}

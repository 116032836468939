import * as I from './interfaces'
import types from './types'

export function login(
  email: string,
  password: string,
  redirect: string,
  history: any
): I.ILoginRequest {
  return {
    email,
    password,
    redirect,
    history,
    type: types.LOGIN_REQUEST,
  }
}

export const saveUser = (
  formData: IUserForm,
  history: any,
  isAdmin: boolean
): I.ISaveUserRequest => ({
  formData,
  history,
  isAdmin,
  type: types.SAVE_USER_REQUEST,
})

export const logout = (): I.ILogoutRequest => ({ type: types.LOGOUT_REQUEST })

export const resetPassword = (
  email: string,
  history: any
): I.IResetPasswordRequest => ({
  email,
  history,
  type: types.PASSWORD_RESET_REQUEST,
})

export const setNewPassword = (
  password: string,
  history: any
): I.ISetNewPasswordRequest => ({
  password,
  history,
  type: types.SET_NEW_PASSWORD_REQUEST,
})

export const verifyOtp = (otp: string, history: any): I.IVerifyOtpRequest => ({
  otp,
  history,
  type: types.VERIFY_OTP_REQUEST,
})

export const getUsers = (
  params?: ISimpleObject,
  paginate = false
): I.IGetUsersRequest => ({
  params,
  paginate,
  type: types.GET_USERS_REQUEST,
})

export const getUser = (id: string): I.IGetUserRequest => ({
  id,
  type: types.GET_USER_REQUEST,
})

export const getAdminUsers = (
  params?: ISimpleObject,
  paginate = false
): I.IGetUsersRequest => ({
  params,
  paginate,
  isAdmin: true,
  type: types.GET_ADMIN_USERS_REQUEST,
})

export const getAdminUser = (id: string): I.IGetUserRequest => ({
  id,
  isAdmin: true,
  type: types.GET_ADMIN_USER_REQUEST,
})

export const updateUser = (
  id: string,
  data: IUserUpdateForm
): I.IUpdateUserRequest => ({
  id,
  data,
  type: types.UPDATE_USER_REQUEST,
})

export const saveDocument = (data: FormData): I.ISaveDocumentRequest => ({
  data,
  type: types.SAVE_DOCUMENT_REQUEST,
})

export const approveDocument = (id: number): I.IApproveDocumentRequest => ({
  id,
  type: types.APPROVE_DOCUMENT_REQUEST,
})

export const deleteAdminUser = (id: number): I.IDeleteUserRequest => ({
  id,
  isAdmin: true,
  type: types.DELETE_USER_REQUEST,
})

export const updatePlan = (
  planId: number,
  data: IUpdatePlanForm
): I.IUpdatePlanRequest => ({
  planId,
  data,
  type: types.UPDATE_PLAN_REQUEST,
})

export const error = (e: string): I.IError => ({
  error: e,
  type: types.ERROR,
})

export const clearError = () => ({ type: types.CLEAR_ERROR })

export const setAuthToken = (token: string) => ({
  token,
  type: types.SET_AUTH_TOKEN,
})

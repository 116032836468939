import classnames from 'classnames'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { PureComponent } from 'react'
import { IOwnProps } from './interfaces'
import useStyles from './styles'

class FilePicker extends PureComponent<
  IOwnProps & {
    classes: Record<'wrapper' | 'loaderWrapper', string>
  }
> {
  inputFile: HTMLInputElement | undefined

  makeInputFileRef = (element: HTMLInputElement) => (this.inputFile = element)

  getData = (file: File) =>
    new Promise<Blob | string>((resolve, reject) => {
      const { maxSize } = this.props
      if (maxSize && file.size > maxSize) {
        reject(new Error('Maximum file size exceeded'))
      }
      resolve(file)
    })

  processFiles = async (files?: FileList) => {
    const { multiple, onUpload, maxCount = 20 } = this.props

    if (files && files.length > 0) {
      if (multiple) {
        try {
          const promises = Array.from(
            new Array(files.length > maxCount ? maxCount : files.length)
          ).map((position, index) => this.getData(files[index]))
          const filesData = await Promise.all(promises)
          onUpload(filesData)
        } catch (error) {
          alert(error.message)
        }
      } else {
        try {
          const file = files[0]
          const fileData = await this.getData(file)
          onUpload([fileData])
        } catch (error) {
          alert(error.message)
        }
      }
    }
  }

  onChange = () => {
    if (this.inputFile && this.inputFile.files) {
      this.processFiles(this.inputFile.files)
    }
  }

  onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    this.processFiles(e.dataTransfer.files)
  }

  onDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
  }

  onDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
  }

  onDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
  }

  onClick = () => {
    if (this.inputFile) {
      this.inputFile.value = ''
      this.inputFile.click()
    }
  }

  render() {
    const {
      children,
      classes,
      isLoading,
      disableDrag = false,
      buttonProps = {},
      buttonClassName,
      wrapperClassName,
      multiple = false,
      accept = 'image/*',
    } = this.props
    return (
      <span className={classnames(classes.wrapper, wrapperClassName)}>
        {isLoading && (
          <span className={classes.loaderWrapper}>
            <CircularProgress size={30} />
          </span>
        )}
        <Button
          className={classnames(buttonClassName, {
            Hidden: isLoading,
          })}
          onClick={this.onClick}
          variant="text"
          {...buttonProps}
        >
          <label
            className="Pointer"
            {...(!disableDrag && {
              onDrop: this.onDrop,
              onDragOver: this.onDragOver,
              onDragLeave: this.onDragLeave,
              onDragEnter: this.onDragEnter,
            })}
          >
            {children}
          </label>
        </Button>
        <input
          hidden
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={this.onChange}
          ref={this.makeInputFileRef}
        />
      </span>
    )
  }
}

export default (props: IOwnProps) => {
  const classes = useStyles()

  return <FilePicker classes={classes} {...props} />
}

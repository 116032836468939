import { Button } from 'components/Form'
import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  onClick = () => {
    window.location.href = '/'
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ height: '100vh' }} className="Flex center column">
          <Box mb={2}>
            <Typography variant="h6">Something went wrong</Typography>
          </Box>
          <Box width={200}>
            <Button onClick={this.onClick}>Go Home</Button>
          </Box>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  loaderWrapper: {
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

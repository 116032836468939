import * as I from './interfaces'
import t from './types'

interface IActionHandler {
  readonly [r: string]: (
    state: I.IFeedState,
    action: I.FeedInterfaces
  ) => I.IFeedState
}

const initialState = {
  isLoading: false,
  loadingFeeds: {},
}

const ACTION_HANDLERS: IActionHandler = {
  [t.GET_FEEDS_REQUEST]: (state: I.IFeedState): I.IFeedState => ({
    ...state,
    isLoading: true,
  }),
  [t.GET_FEEDS_SUCCESS]: (
    state: I.IFeedState,
    { feeds }: I.IGetFeedsSuccess
  ): I.IFeedState => ({
    ...state,
    isLoading: false,
    feeds,
  }),
  [t.GET_FEEDS_ERROR]: (state: I.IFeedState): I.IFeedState => ({
    ...state,
    isLoading: false,
  }),
  [t.GET_FEED_REQUEST]: (state: I.IFeedState): I.IFeedState => ({
    ...state,
    isLoading: true,
    activeFeed: undefined,
  }),
  [t.GET_FEED_SUCCESS]: (
    state: I.IFeedState,
    { activeFeed }: I.IGetFeedSuccess
  ): I.IFeedState => ({
    ...state,
    activeFeed,
    isLoading: false,
  }),
  [t.GET_FEED_ERROR]: (state: I.IFeedState): I.IFeedState => ({
    ...state,
    isLoading: false,
    activeFeed: undefined,
  }),
  [t.DELETE_FEED_REQUEST]: (
    state: I.IFeedState,
    { id }: I.IDeleteFeedRequest
  ): I.IFeedState => ({
    ...state,
    loadingFeeds: {
      ...state.loadingFeeds,
      [id]: true,
    },
  }),
  [t.DELETE_FEED_SUCCESS]: (
    state: I.IFeedState,
    { id: feedId }: I.IDeleteFeedSuccess
  ): I.IFeedState => {
    const { data, meta } = state.feeds as IFeedObject
    const { total, offset } = meta

    return {
      ...state,
      loadingFeeds: {
        ...state.loadingFeeds,
        [feedId]: false,
      },
      feeds: {
        data: data.filter(({ id }) => id !== feedId),
        meta: {
          ...meta,
          total: total - 1,
          offset: !!offset ? offset - 1 : offset,
        },
      },
    }
  },
  [t.DELETE_FEED_ERROR]: (
    state: I.IFeedState,
    { id }: I.IDeleteFeedError
  ): I.IFeedState => ({
    ...state,
    loadingFeeds: {
      ...state.loadingFeeds,
      [id]: false,
    },
  }),
}

export default function reducer(
  state = initialState,
  action: I.FeedInterfaces
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import IListProp from './interfaces'
import useStyles from './styles'

export default ({ options, input }: IListProp) => {
  const classes = useStyles()

  const handleToggle = (value: string | number) => () => {
    const currentIndex = input.value.indexOf(value)
    const newChecked = [...input.value]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    input.onChange(newChecked)
  }

  return (
    <List className={classes.root}>
      {options.map(({ value, texts }) => {
        const labelId = `checkbox-list-label-${value}`

        return (
          <ListItem
            key={value}
            role={undefined}
            dense
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={input.value.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            {texts.map((text, textIndex) => (
              <ListItemText
                key={`${value}-${textIndex}`}
                id={`${labelId}-${textIndex}`}
                primary={text}
              />
            ))}
          </ListItem>
        )
      })}
    </List>
  )
}

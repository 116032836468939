enum Types {
  LOGIN_REQUEST = 'user/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'user/LOGIN_SUCCESS',
  LOGIN_ERROR = 'user/LOGIN_ERROR',

  SAVE_USER_REQUEST = 'user/SAVE_USER_REQUEST',
  SAVE_USER_SUCCESS = 'user/SAVE_USER_SUCCESS',
  SAVE_USER_ERROR = 'user/SAVE_USER_ERROR',

  LOGOUT_REQUEST = 'user/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'user/LOGOUT_ERROR',

  PASSWORD_RESET_REQUEST = 'user/PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS = 'user/PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR = 'user/PASSWORD_RESET_ERROR',

  VERIFY_OTP_REQUEST = 'user/VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS = 'user/VERIFY_OTP_SUCCESS',
  VERIFY_OTP_ERROR = 'user/VERIFY_OTP_ERROR',

  SET_NEW_PASSWORD_REQUEST = 'user/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS = 'user/SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR = 'user/SET_NEW_PASSWORD_ERROR',

  GET_AUTH_USER_ERROR = 'user/GET_AUTH_USER_ERROR',

  SET_AUTH_TOKEN = 'user/SET_AUTH_TOKEN',
  SET_USER_PROFILE = 'user/SET_USER_PROFILE',

  GET_USERS_REQUEST = 'user/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'user/GET_USERS_SUCCESS',
  GET_USERS_ERROR = 'user/GET_USERS_ERROR',

  GET_ADMIN_USERS_REQUEST = 'user/GET_ADMIN_USERS_REQUEST',
  GET_ADMIN_USERS_SUCCESS = 'user/GET_ADMIN_USERS_SUCCESS',
  GET_ADMIN_USERS_ERROR = 'user/GET_ADMIN_USERS_ERROR',

  GET_ADMIN_USER_REQUEST = 'user/GET_ADMIN_USER_REQUEST',
  GET_ADMIN_USER_SUCCESS = 'user/GET_ADMIN_USER_SUCCESS',
  GET_ADMIN_USER_ERROR = 'user/GET_ADMIN_USER_ERROR',

  GET_USER_REQUEST = 'user/GET_USER_REQUEST',
  GET_USER_SUCCESS = 'user/GET_USER_SUCCESS',
  GET_USER_ERROR = 'user/GET_USER_ERROR',

  UPDATE_USER_REQUEST = 'user/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'user/UPDATE_USER_ERROR',

  APPROVE_DOCUMENT_REQUEST = 'user/APPROVE_DOCUMENT_REQUEST',
  APPROVE_DOCUMENT_SUCCESS = 'user/APPROVE_DOCUMENT_SUCCESS',
  APPROVE_DOCUMENT_ERROR = 'user/APPROVE_DOCUMENT_ERROR',

  SAVE_DOCUMENT_REQUEST = 'user/SAVE_DOCUMENT_REQUEST',
  SAVE_DOCUMENT_SUCCESS = 'user/SAVE_DOCUMENT_SUCCESS',
  SAVE_DOCUMENT_ERROR = 'user/SAVE_DOCUMENT_ERROR',

  DELETE_USER_REQUEST = 'user/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'user/DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'user/DELETE_USER_ERROR',

  UPDATE_PLAN_REQUEST = 'user/UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS = 'user/UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_ERROR = 'user/UPDATE_PLAN_ERROR',

  ERROR = 'user/ERROR',
  CLEAR_ERROR = 'user/ERROR_CLEAR',
}

export default Types

import { createFeed, deleteFeed, getFeeds, getFeed, updateFeed } from 'api'
import { startSubmit, stopSubmit } from 'redux-form'
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { setNotification } from 'redux/application/actions'
import { createFeedForm } from 'redux/forms'
import { getFeedsObject } from './selectors'
import * as I from './interfaces'
import t from './types'

export function* getFeedsSaga({ paginate, params }: I.IGetFeedsRequest) {
  try {
    if (paginate) {
      const stateFeeds = yield select(getFeedsObject)
      const {
        data,
        meta: { limit, offset, total },
      } = stateFeeds
      let paginatedFeeds = { ...stateFeeds }

      if (data.length < total) {
        const { data: feeds } = yield call(getFeeds, {
          $offset: !!offset ? offset + limit : data.length,
          ...params,
        })
        paginatedFeeds = {
          ...feeds,
          data: [...stateFeeds.data, ...feeds.data],
        }
      }

      yield put({
        type: t.GET_FEEDS_SUCCESS,
        feeds: paginatedFeeds,
      })
    } else {
      const { data: feeds } = yield call(getFeeds, params)

      yield put({
        type: t.GET_FEEDS_SUCCESS,
        feeds,
      })
    }
  } catch (error) {
    yield put({
      type: t.GET_FEEDS_ERROR,
      error,
    })
  }
}

function* getFeedSaga({ id }: I.IGetFeedRequest) {
  try {
    const {
      data: { data: activeFeed },
    } = yield call(getFeed, id)
    yield put({
      type: t.GET_FEED_SUCCESS,
      activeFeed,
    } as I.IGetFeedSuccess)
  } catch (error) {
    yield put({
      error,
      type: t.GET_FEED_ERROR,
    })
  }
}

function* deleteFeedSaga({ id }: I.IDeleteFeedRequest) {
  try {
    yield call(deleteFeed, id)
    yield put({
      id,
      type: t.DELETE_FEED_SUCCESS,
    } as I.IDeleteFeedSuccess)
  } catch (error) {
    yield put({
      id,
      error,
      type: t.DELETE_FEED_ERROR,
    } as I.IDeleteFeedError)
  }
}

function* createFeedSaga({ data, history }: I.ICreateFeedRequest) {
  try {
    const { id, ...restData } = data
    yield put(startSubmit(createFeedForm.name))
    if (id === 'new') {
      yield call(createFeed, restData)
    } else {
      yield call(updateFeed, id as number, restData)
    }
    yield put(stopSubmit(createFeedForm.name))
    yield put({ type: t.SAVE_FEED_SUCCESS })
    history.push('/dashboard/feed')
    yield put(setNotification('Feed successfully saved'))
  } catch (error) {
    yield put(stopSubmit(createFeedForm.name))
    yield put({
      error,
      type: t.SAVE_FEED_ERROR,
    })
  }
}

function* watchCreateFeedSaga() {
  yield takeEvery(t.SAVE_FEED_REQUEST, createFeedSaga)
}

function* watchGetFeedsSaga() {
  yield takeLatest(t.GET_FEEDS_REQUEST, getFeedsSaga)
}

function* watchGetFeedSaga() {
  yield takeLatest(t.GET_FEED_REQUEST, getFeedSaga)
}

function* watchDeleteFeedSaga() {
  yield takeEvery(t.DELETE_FEED_REQUEST, deleteFeedSaga)
}

export default function* () {
  yield all([
    fork(watchGetFeedsSaga),
    fork(watchGetFeedSaga),
    fork(watchCreateFeedSaga),
    fork(watchDeleteFeedSaga),
  ])
}

import React from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'

interface SwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#00CCBB',
        '& + $track': {
          opacity: 1,
          backgroundColor: 'white',
          borderColor: '#00CCBB',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch)

export default ({ checked, onChange }: SwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <FormGroup>
      <AntSwitch checked={checked} onChange={handleChange} name="checkedC" />
    </FormGroup>
  )
}

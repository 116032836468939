import { checkStatus, makeRestOptions } from './utils'

const parsers = {
  jsonParser: checkStatus('json'),
  blobParser: checkStatus('blob')
}

const shouldLogout = (notNullItems: any[]) => {
  let logout = false
  notNullItems.forEach((item: any) => {
    if (!item) {
      logout = true
    }
  })
  return logout
}

export const fetchRequest = async (
  endpoint: string,
  method: IAPIMethods,
  body?: any,
  extraHeader?: IAPIExtraHeader,
  notNullItems?: (string | number)[]
) => {
  if (notNullItems) {
    const logout = shouldLogout(notNullItems)
    if (logout) {
      return Promise.reject(new Error('logout'))
    }
  }

  try {
    const { url, options: restOptions, ...rest } = await makeRestOptions(
      endpoint,
      method,
      body,
      extraHeader
    )
    const response = await fetch(url, { ...restOptions, ...rest })
    return parsers.jsonParser(response)
  } catch (error) {
    const res = { message: error.message }
    throw res
  }
}

import Root from 'containers/Root'
import React from 'react'
import { render } from 'react-dom'
import configureStore from 'redux/configureStore'
import history from 'redux/history'
import { unregister } from './serviceWorker'
import setup from './setup'

const initialState = {}
export const store = configureStore(initialState, history)

unregister()
setup()

render(
  <React.StrictMode>
    <Root history={history} store={store} />
  </React.StrictMode>,
  document.getElementById('root')
)

import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { Button } from '@material-ui/core'
import IButtonProps from './interfaces'
import styles from './style'

const Btn = ({
  children,
  disabled,
  isLoading,
  onClick,
  customVariant,
  variant = 'contained',
  ...rest
}: IButtonProps) => {
  const classes = styles()
  let useClass = variant === 'contained' ? classes.root : ''
  if (customVariant) {
    useClass = classes[customVariant as 'primary']
  }

  return isLoading ? (
    <div className={classes.loaderWrapper}>
      <CircularProgress />
    </div>
  ) : (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={useClass}
      fullWidth={!customVariant}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default Btn

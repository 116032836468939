import { constants } from 'config'
import { Action } from 'redux'
import { actionChannel, put, take } from 'redux-saga/effects'
import { error as displayErrorAction, logout } from 'redux/user/actions'

export interface IError extends Action {
  error: Error | string
  hide?: boolean
}

const { DEFAULT_ERROR_MESSAGE } = constants

export default function* errorSaga(): any {
  const requestChan = yield actionChannel((action: IError) =>
    action.type.match(/_ERROR$/g)
  )
  while (true) {
    const { error } = yield take(requestChan)
    console.log(error)

    if (
      error?.errors?.code === 'invalid_token' ||
      error?.errors?.code === 'expired_token'
    ) {
      yield put(logout())
      continue
    }
    const errorMessage =
      typeof error === 'string' || error == null ? error : DEFAULT_ERROR_MESSAGE
    yield put(
      displayErrorAction(error && error.message ? error.message : errorMessage)
    )
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IInputWrapperProps } from './interfaces'
import Input from './Input'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}))

const InputWrapper = (props: IInputWrapperProps) => {
  const classes = useStyles()
  return <Input classes={classes} {...props} />
}

export default InputWrapper

import {
  addDocument,
  approveDocument,
  createAdminUser,
  createUser,
  deleteUser,
  forgotPassword,
  getDetails,
  getDocuments,
  getPlans,
  getStats,
  getTransactions,
  getUser as getUserApi,
  getUsers as getUsersApi,
  getWallet,
  login,
  resetPasswordOtp,
  setNewPassword,
  updatePlan,
  updateUser,
} from 'api'
import {
  removeSessionData,
  saveSessionToken,
  saveSessionUserData,
} from 'api/session'
import { startSubmit, stopSubmit } from 'redux-form'
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { setNotification } from 'redux/application/actions'
import {
  customerDetailForm,
  loginForm,
  newPasswordForm,
  passwordResetForm,
  saveUserForm,
  verifyOtpForm,
} from 'redux/forms'
import { setAuthToken } from './actions'
import * as I from './interfaces'
import { getActiveUser, getAdminUsersObject, getUsersObject } from './selectors'
import t from './types'

function* getDetailsSaga(params?: any) {
  try {
    const { data } = yield call(getDetails, params)
    const {
      data: { data: userData },
    } = yield call(getUserApi, data.id, params)
    const user = { ...data, ...userData }

    if (!user.isAdmin) {
      removeSessionData()
      throw new Error('You are not Authorized to use this service')
    }

    saveSessionUserData(user)
    return user
  } catch (error) {
    yield put({ type: t.GET_AUTH_USER_ERROR, error })
    return error
  }
}

function* loginSaga({ email, password }: I.ILoginRequest) {
  try {
    yield put(startSubmit(loginForm.name))
    const {
      data: { token, expires },
    } = yield call(login, email, password)
    yield put(setAuthToken(token))

    saveSessionToken({ token, expires })
    const userOrError = yield call(getDetailsSaga, {
      $include: 'admin_group.admin_group_permission.admin_permission, account',
    })
    yield put(stopSubmit(loginForm.name))
    if (!(userOrError instanceof Error)) {
      yield put({ type: t.LOGIN_SUCCESS })
      yield put({
        type: t.SET_USER_PROFILE,
        user: userOrError,
      })
    }
  } catch (error) {
    yield put({ type: t.LOGIN_ERROR, error })
    yield put(stopSubmit(loginForm.name))
  }
}

function* saveUserSaga({ isAdmin, formData, history }: I.ISaveUserRequest) {
  try {
    const { id, ...restData } = formData as IUserForm
    yield put(startSubmit(saveUserForm.name))

    if (id) {
      if (id === 'new') {
        if (isAdmin) {
          yield call(createAdminUser, restData)
        } else {
          yield call(createUser, restData)
        }
      } else {
        yield call(updateUser, id, restData as IUserUpdateForm)
      }
    }
    yield put(stopSubmit(saveUserForm.name))
    yield put({ type: t.SAVE_USER_SUCCESS })
    if (isAdmin) {
      history.push('/dashboard/admin')
    } else {
      history.push('/dashboard/user')
    }
    yield put(setNotification('User successfully saved'))
  } catch (error) {
    yield put(stopSubmit(saveUserForm.name))
    yield put({
      error,
      type: t.SAVE_USER_ERROR,
    })
  }
}

function* passwordResetSaga({ email, history }: I.ILoginRequest) {
  try {
    yield put(startSubmit(passwordResetForm.name))
    yield call(forgotPassword, email)
    yield put(stopSubmit(passwordResetForm.name))
    yield put({ type: t.PASSWORD_RESET_SUCCESS })
    history.push('/reset_password/otp')
  } catch (error) {
    yield put(stopSubmit(passwordResetForm.name))
    yield put({
      type: t.PASSWORD_RESET_ERROR,
      error,
    })
  }
}

function* verifyOtpdSaga({ otp, history }: I.IVerifyOtpRequest) {
  try {
    yield put(startSubmit(verifyOtpForm.name))

    const {
      data: { token, expires },
    } = yield call(resetPasswordOtp, otp)
    yield put(setAuthToken(token))

    saveSessionToken({ token, expires })
    const userOrError = yield call(getDetailsSaga, {
      $include: 'account',
    })

    yield put(stopSubmit(verifyOtpForm.name))
    if (userOrError instanceof Error) {
      history.push('/login')
    } else {
      yield put({ type: t.VERIFY_OTP_SUCCESS })
      yield put({
        type: t.SET_USER_PROFILE,
        user: userOrError,
      })
      history.push('/reset_password/new')
    }
  } catch (error) {
    yield put(stopSubmit(verifyOtpForm.name))
    yield put({
      type: t.VERIFY_OTP_ERROR,
      error,
    })
  }
}

function* setNewPasswordSaga({ password, history }: I.ISetNewPasswordRequest) {
  try {
    yield put(startSubmit(newPasswordForm.name))
    yield call(setNewPassword, password)
    yield put({ type: t.SET_NEW_PASSWORD_SUCCESS })
    yield put(stopSubmit(newPasswordForm.name))
    yield put(setNotification('Password successfully reset'))
    history.push('/')
  } catch (error) {
    yield put(stopSubmit(newPasswordForm.name))
    yield put({
      type: t.SET_NEW_PASSWORD_ERROR,
      error,
    })
  }
}

function* logoutSaga() {
  removeSessionData()
  yield put({ type: t.LOGOUT_SUCCESS })
}

function* getUsersSaga({ paginate, params, isAdmin }: I.IGetUsersRequest) {
  try {
    if (paginate) {
      const stateUsers = yield select(
        isAdmin ? getAdminUsersObject : getUsersObject
      )
      const {
        data,
        meta: { limit, offset, total },
      } = stateUsers
      let paginatedUsers = { ...stateUsers }

      if (data.length < total) {
        const { data: users } = yield call(getUsersApi, {
          $offset: !!offset ? offset + limit : data.length,
          ...params,
        })
        paginatedUsers = {
          ...users,
          data: [...stateUsers.data, ...users.data],
        }
      }

      if (isAdmin) {
        yield put({
          type: t.GET_ADMIN_USERS_SUCCESS,
          users: paginatedUsers,
        } as I.IGetAdminUsersSuccess)
      } else {
        yield put({
          type: t.GET_USERS_SUCCESS,
          users: paginatedUsers,
        } as I.IGetUsersSuccess)
      }
    } else {
      const { data: users } = yield call(getUsersApi, params)

      if (isAdmin) {
        yield put({
          type: t.GET_ADMIN_USERS_SUCCESS,
          users,
        } as I.IGetAdminUsersSuccess)
      } else {
        yield put({
          type: t.GET_USERS_SUCCESS,
          users,
        } as I.IGetUsersSuccess)
      }
    }
  } catch (error) {
    if (isAdmin) {
      yield put({
        type: t.GET_ADMIN_USERS_ERROR,
        error,
      })
    } else {
      yield put({
        type: t.GET_USERS_ERROR,
        error,
      })
    }
  }
}

function* getUserSaga({ id, isAdmin }: I.IGetUserRequest): any {
  try {
    if (isAdmin) {
      const {
        data: { data: user },
      } = yield call(getUserApi, id)
      yield put({
        type: t.GET_ADMIN_USER_SUCCESS,
        user,
      } as I.IGetAdminUserSuccess)
    } else {
      const [userCall, statsCall, walletCall] = yield all([
        call(getUserApi, id),
        call(getStats, { userId: id }),
        call(getWallet, id),
      ])
      const {
        data: { data: user },
      } = userCall
      const {
        data: { data: stats },
      } = statsCall
      const {
        data: {
          data: { wallet },
        },
      } = walletCall

      const [
        pendingWithdrawalsCall,
        transactionHistoryCall,
        plansCall,
        documentsCall,
      ] = yield all([
        call(getTransactions, {
          status: 'pending approval',
          type: 'withdrawal',
          $q: user.email,
          $limit: 100,
        }),
        call(getTransactions, {
          $q: user.email,
          $nopaginate: 1,
          $orderBy: 'createdAt',
          $order: 'desc',
        }),
        call(getPlans, {
          userId: id,
          $limit: 100,
          isClosed: false,
        }),
        call(getDocuments, id),
      ])

      const {
        data: { data: pendingWithdrawals },
      } = pendingWithdrawalsCall
      const {
        data: { data: transactionHistory },
      } = transactionHistoryCall
      const {
        data: { data: documents },
      } = documentsCall
      const {
        data: { data: plans },
      } = plansCall

      yield put({
        type: t.GET_USER_SUCCESS,
        user: {
          ...user,
          wallet,
          stats,
          plans,
          documents,
          transactionHistory,
          pendingWithdrawals,
        },
      } as I.IGetUserSuccess)
    }
  } catch (error) {
    if (isAdmin) {
      yield put({ type: t.GET_ADMIN_USER_ERROR, error })
    } else {
      yield put({ type: t.GET_USER_ERROR, error })
    }
  }
}

function* updateUserSaga({ id, data }: I.IUpdateUserRequest) {
  try {
    const activeUser = yield select(getActiveUser)
    yield put(startSubmit(customerDetailForm.name))
    yield call(updateUser, id, data)

    yield put(setNotification('Profile successfully saved'))
    yield put(stopSubmit(customerDetailForm.name))
    yield put({ type: t.UPDATE_USER_SUCCESS })
    yield put({
      type: t.GET_USER_SUCCESS,
      user: {
        ...activeUser,
        ...data,
      },
    } as I.IGetUserSuccess)
  } catch (error) {
    yield put(stopSubmit(customerDetailForm.name))
    yield put({ type: t.UPDATE_USER_ERROR, error })
  }
}

function* saveDocumentSaga({ data }: I.ISaveDocumentRequest) {
  try {
    const { id } = yield select(getActiveUser)
    yield call(addDocument, id, data)
    const {
      data: { data: documents },
    } = yield call(getDocuments, id)
    yield put({
      type: t.SAVE_DOCUMENT_SUCCESS,
      documents,
    } as I.ISaveDocumentSuccess)
  } catch (error) {
    yield put({
      type: t.SAVE_DOCUMENT_ERROR,
      error,
    })
  }
}

function* approveDocumentSaga({ id }: I.IApproveDocumentRequest) {
  try {
    yield call(approveDocument, id)
    yield put({
      id,
      type: t.APPROVE_DOCUMENT_SUCCESS,
    } as I.IApproveDocumentSuccess)
  } catch (error) {
    yield put({
      type: t.APPROVE_DOCUMENT_ERROR,
      error,
    })
  }
}

function* deleteUserSaga({ id, isAdmin }: I.IDeleteUserRequest) {
  try {
    yield call(deleteUser, id)
    yield put({
      id,
      isAdmin,
      type: t.DELETE_USER_SUCCESS,
    } as I.IDeleteUserSuccess)
  } catch (error) {
    yield put({
      id,
      error,
      isAdmin,
      type: t.DELETE_USER_ERROR,
    } as I.IDeleteUserError)
  }
}

function* updatePlanSaga({ planId, data }: I.IUpdatePlanRequest) {
  try {
    yield call(updatePlan, planId, data)
    yield put({
      data,
      planId,
      type: t.UPDATE_PLAN_SUCCESS,
    } as I.IUpdatePlanSuccess)
  } catch (error) {
    yield put({
      type: t.UPDATE_PLAN_ERROR,
      error,
      planId,
    } as I.IUpdatePlanError)
  }
}

function* watchLoginSaga() {
  yield takeLatest(t.LOGIN_REQUEST, loginSaga)
}

function* watchSaveUserSaga() {
  yield takeEvery(t.SAVE_USER_REQUEST, saveUserSaga)
}

function* watchLogoutSaga() {
  yield takeLatest(t.LOGOUT_REQUEST, logoutSaga)
}

function* watchPasswordResetSaga() {
  yield takeLatest(t.PASSWORD_RESET_REQUEST, passwordResetSaga)
}

function* watchVerifyOtpdSaga() {
  yield takeLatest(t.VERIFY_OTP_REQUEST, verifyOtpdSaga)
}

function* watchSetNewPasswordSaga() {
  yield takeLatest(t.SET_NEW_PASSWORD_REQUEST, setNewPasswordSaga)
}

function* watchGetUsersSaga() {
  yield takeLatest(t.GET_USERS_REQUEST, getUsersSaga)
}

function* watchGetUserSaga() {
  yield takeLatest(t.GET_USER_REQUEST, getUserSaga)
}

function* watchGetAdminUsersSaga() {
  yield takeLatest(t.GET_ADMIN_USERS_REQUEST, getUsersSaga)
}

function* watchGetAdminUserSaga() {
  yield takeLatest(t.GET_ADMIN_USER_REQUEST, getUserSaga)
}

function* watchUpdateUserSaga() {
  yield takeLatest(t.UPDATE_USER_REQUEST, updateUserSaga)
}

function* watchSaveDocumentSaga() {
  yield takeLatest(t.SAVE_DOCUMENT_REQUEST, saveDocumentSaga)
}

function* watchApproveDocumentSaga() {
  yield takeEvery(t.APPROVE_DOCUMENT_REQUEST, approveDocumentSaga)
}

function* watchDeleteUserSaga() {
  yield takeEvery(t.DELETE_USER_REQUEST, deleteUserSaga)
}

function* watchUpdatePlanSaga() {
  yield takeEvery(t.UPDATE_PLAN_REQUEST, updatePlanSaga)
}

export default function* () {
  yield all([
    fork(watchLoginSaga),
    fork(watchSaveUserSaga),
    fork(watchLogoutSaga),
    fork(watchPasswordResetSaga),
    fork(watchVerifyOtpdSaga),
    fork(watchSetNewPasswordSaga),
    fork(watchGetUsersSaga),
    fork(watchGetUserSaga),
    fork(watchGetAdminUsersSaga),
    fork(watchGetAdminUserSaga),
    fork(watchUpdateUserSaga),
    fork(watchSaveDocumentSaga),
    fork(watchApproveDocumentSaga),
    fork(watchDeleteUserSaga),
    fork(watchUpdatePlanSaga),
  ])
}

import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@material-ui/icons/Event'
import { DatePicker } from '@material-ui/pickers'
import IPickerProps from './interfaces'
import useStyles from './styles'

export default (props: IPickerProps) => {
  const {
    input: { value, onChange, onBlur, ...restInput }, // onBlur is factored out to fix Date change on Blur
    meta: { touched, error },
    helperText,
    dateFormat,
    margin = 'normal',
    ...restProps
  } = props
  const classes = useStyles(props)
  const hasError = !!(touched && error)
  const helper = hasError ? error : helperText

  return (
    <div className={classes.root}>
      <DatePicker
        autoOk
        disableToolbar
        error={hasError}
        variant="inline"
        margin={margin}
        format={dateFormat}
        onChange={onChange}
        value={value || null}
        inputVariant="outlined"
        className={classes.input}
        helperText={helper || ' '}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EventIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
        onBlur={(e: any) => {
          e.preventDefault()
          value && onBlur(value)
        }}
        {...restProps}
        {...restInput}
      />
    </div>
  )
}

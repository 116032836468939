import { Theme } from '@material-ui/core/styles'

const composeGenericStyles = (theme: Theme) => {
  const margins = [0, 1, 2, 3, 4, 5].reduce((prev: any, curr) => {
    prev[`.m-${curr}`] = {
      margin: theme.spacing(curr),
    }
    return prev
  }, {})

  const marginBottoms = [0, 1, 2, 3, 4, 5, 6].reduce((prev: any, curr) => {
    prev[`.mb-${curr}`] = {
      marginBottom: theme.spacing(curr),
    }
    return prev
  }, margins)

  const marginTops = [0, 1, 2, 3, 4, 5, 6].reduce((prev: any, curr) => {
    prev[`.mt-${curr}`] = {
      marginTop: theme.spacing(curr),
    }
    return prev
  }, marginBottoms)

  const marginLefts = [0, 1, 2, 3, 4, 5, 6].reduce((prev: any, curr) => {
    prev[`.ml-${curr}`] = {
      marginLeft: theme.spacing(curr),
    }
    return prev
  }, marginTops)

  const marginRights = [0, 1, 2, 3, 4, 5, 6].reduce((prev: any, curr) => {
    prev[`.mr-${curr}`] = {
      marginRight: theme.spacing(curr),
    }
    return prev
  }, marginLefts)

  const fontWeights = ['500', '600'].reduce((prev: any, curr) => {
    prev[`.fw-${curr}`] = {
      fontWeight: curr,
    }
    return prev
  }, {})

  const generics = {
    ...marginRights,
    ...fontWeights,
  }

  return generics
}

export default composeGenericStyles

import {
  approveTransaction,
  batchApproveDeclineTransactions,
  declineTransaction,
  fund,
  getDocuments,
  getInvestmentPlans,
  getTransaction,
  getTransactions,
  getWallet,
} from 'api'
import { startSubmit, stopSubmit } from 'redux-form'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { setNotification } from 'redux/application/actions'
import { customerPendingWithdrawalForm } from 'redux/forms'
import { IGetUserRequest } from 'redux/user/interfaces'
import userTypes from 'redux/user/types'
import * as I from './interfaces'
import { getTransactionsObject } from './selectors'
import t from './types'

function* getTransactionDetailsSaga({
  transactionId,
}: I.IGetTransactionDetailsRequest): any {
  try {
    const {
      data: { data: transaction },
    } = yield call(getTransaction, transactionId, {
      $include: 'bank_account,user_plan,account.user',
    })

    const userId = transaction.account.users[0].id

    const calls = [call(getWallet, userId), call(getDocuments, userId)]

    if (transaction.userPlanId) {
      calls.push(call(getInvestmentPlans))
      calls.push(
        call(getTransactions, {
          $limit: 100,
          userPlanId: transaction.userPlanId,
        })
      )
    }

    const [
      walletCall,
      documentsCall,
      investmentPlansCall,
      planTransactionsCall,
    ] = yield all(calls)
    const {
      data: {
        data: { wallet, transactions: walletTransactions },
      },
    } = walletCall
    const {
      data: { data: documents },
    } = documentsCall

    yield put({
      type: t.GET_TRANSACTION_DETAILS_SUCCESS,
      transaction: {
        ...transaction,
        wallet,
        documents,
        walletTransactions,
        investmentPlans: investmentPlansCall && investmentPlansCall.data.data,
        planTransactions:
          planTransactionsCall && planTransactionsCall.data.data,
      },
    } as I.IGetTransactionDetailsSuccess)
  } catch (error) {
    yield put({ type: t.GET_TRANSACTION_DETAILS_ERROR, error })
  }
}

function* getTransactionsSaga({ paginate, params }: I.IGetTransactionsRequest) {
  try {
    if (paginate) {
      const stateTransactions = yield select(getTransactionsObject)
      const {
        data,
        meta: { limit, offset, total },
      } = stateTransactions
      let paginatedTransactions = { ...stateTransactions }

      if (data.length < total) {
        const { data: transactions } = yield call(getTransactions, {
          $offset: !!offset ? offset + limit : data.length,
          ...params,
        })
        paginatedTransactions = {
          ...transactions,
          data: [...stateTransactions.data, ...transactions.data],
        }
      }

      yield put({
        type: t.GET_TRANSACTIONS_SUCCESS,
        transactions: paginatedTransactions,
      })
    } else {
      const { data: transactions } = yield call(getTransactions, params)

      yield put({
        type: t.GET_TRANSACTIONS_SUCCESS,
        transactions,
      })
    }
  } catch (error) {
    yield put({
      type: t.GET_TRANSACTIONS_ERROR,
      error,
    })
  }
}

function* approveTransactionSaga({
  id,
  history,
}: I.IApproveTransactionRequest) {
  try {
    yield call(approveTransaction, id)
    yield put({
      type: t.APPROVE_TRANSACTION_SUCCESS,
      id,
    } as I.IApproveTransactionSuccess)
    yield put(setNotification('Transaction successfully Approved'))
    history.push('/dashboard/withdrawal')
  } catch (error) {
    yield put({ type: t.APPROVE_TRANSACTION_ERROR, error })
  }
}

function* declineTransactionSaga({
  id,
  history,
}: I.IDeclineTransactionRequest) {
  try {
    yield call(declineTransaction, id)
    yield put({
      type: t.DECLINE_TRANSACTION_SUCCESS,
      id,
    } as I.IDeclineTransactionSuccess)
    yield put(setNotification('Transaction successfully Declined'))
    history.push('/dashboard/withdrawal')
  } catch (error) {
    yield put({ type: t.DECLINE_TRANSACTION_ERROR, error })
  }
}

function* batchApproveDeclineTransactionsSaga({
  data,
}: I.IBatchApproveDeclineTransactionsRequest) {
  const { action, transactions } = data
  try {
    yield put(startSubmit(customerPendingWithdrawalForm.name))
    yield call(batchApproveDeclineTransactions, data)

    yield put({
      type: t.BATCH_APPROVE_DECLINE_TRANSACTIONS_SUCCESS,
      transactionIds: transactions,
    } as I.IBatchApproveDeclineTransactionsSuccess)

    yield put(stopSubmit(customerPendingWithdrawalForm.name))
    yield put(
      setNotification(
        `Transaction${
          transactions.length > 1 ? 's' : ''
        } successfully ${action}d`
      )
    )
  } catch (error) {
    yield put(stopSubmit(customerPendingWithdrawalForm.name))
    yield put({ type: t.BATCH_APPROVE_DECLINE_TRANSACTIONS_ERROR, error })
  }
}

function* fundSaga({ data }: I.IFundRequest) {
  try {
    yield call(fund, data)
    yield put({ type: t.FUND_SUCCESS })
    yield put({
      id: data.userId,
      type: userTypes.GET_USER_REQUEST,
    } as IGetUserRequest)
    yield put(setNotification('Funding successful! Reloading...'))
  } catch (error) {
    yield put({ type: t.FUND_ERROR, error })
  }
}

function* watchApproveTransactionSaga() {
  yield takeLatest(t.APPROVE_TRANSACTION_REQUEST, approveTransactionSaga)
}

function* watchDeclineTransactionSaga() {
  yield takeLatest(t.DECLINE_TRANSACTION_REQUEST, declineTransactionSaga)
}

function* watchBatchApproveDeclineTransactionsSaga() {
  yield takeLatest(
    t.BATCH_APPROVE_DECLINE_TRANSACTIONS_REQUEST,
    batchApproveDeclineTransactionsSaga
  )
}

function* watchGetTransactionsSaga() {
  yield takeLatest(t.GET_TRANSACTIONS_REQUEST, getTransactionsSaga)
}

function* watchGetTransactionDetailsSaga() {
  yield takeLatest(t.GET_TRANSACTION_DETAILS_REQUEST, getTransactionDetailsSaga)
}

function* watchFundSaga() {
  yield takeLatest(t.FUND_REQUEST, fundSaga)
}

export default function* () {
  yield all([
    fork(watchFundSaga),
    fork(watchGetTransactionDetailsSaga),
    fork(watchGetTransactionsSaga),
    fork(watchApproveTransactionSaga),
    fork(watchDeclineTransactionSaga),
    fork(watchBatchApproveDeclineTransactionsSaga),
  ])
}

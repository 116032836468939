export default function () {
  const StringPrototype: any = String.prototype
  if (StringPrototype.capitalize) {
    console.warn(
      "Overriding existing String.prototype.capitalize. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
    )
    // attach the .capitalize method to String's prototype to call it on any String
  }
  StringPrototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
  }
}

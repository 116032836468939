import * as I from './interfaces'
import t from './types'

interface IActionHandler {
  readonly [r: string]: (
    state: I.ITransactionState,
    action: I.TransactionInterfaces
  ) => I.ITransactionState
}

// Fix rootReducer error
const getActiveTransaction = (): any => null

const initialState = {
  isFunding: false,
  isLoading: false,
  isLoadingActiveTransaction: true,
  isProcessingActiveTransaction: false,
  activeTransaction: getActiveTransaction(),
}

const ACTION_HANDLERS: IActionHandler = {
  [t.GET_TRANSACTIONS_REQUEST]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isLoading: true,
  }),
  [t.GET_TRANSACTIONS_SUCCESS]: (
    state: I.ITransactionState,
    { transactions }: I.IGetTransactionsSuccess
  ): I.ITransactionState => ({
    ...state,
    isLoading: false,
    transactions,
  }),
  [t.GET_TRANSACTIONS_ERROR]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isLoading: false,
  }),
  [t.GET_TRANSACTION_DETAILS_REQUEST]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    activeTransaction: null,
    isLoadingActiveTransaction: true,
  }),
  [t.GET_TRANSACTION_DETAILS_SUCCESS]: (
    state: I.ITransactionState,
    { transaction: activeTransaction }: I.IGetTransactionDetailsSuccess
  ): I.ITransactionState => ({
    ...state,
    isLoadingActiveTransaction: false,
    activeTransaction,
  }),
  [t.GET_TRANSACTION_DETAILS_ERROR]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isLoadingActiveTransaction: false,
  }),
  [t.FUND_REQUEST]: (state: I.ITransactionState): I.ITransactionState => ({
    ...state,
    isFunding: true,
  }),
  [t.FUND_SUCCESS]: (state: I.ITransactionState): I.ITransactionState => ({
    ...state,
    isFunding: false,
  }),
  [t.FUND_ERROR]: (state: I.ITransactionState): I.ITransactionState => ({
    ...state,
    isFunding: false,
  }),
  [t.APPROVE_TRANSACTION_REQUEST]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isProcessingActiveTransaction: true,
  }),
  [t.APPROVE_TRANSACTION_ERROR]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isProcessingActiveTransaction: false,
  }),
  [t.APPROVE_TRANSACTION_SUCCESS]: (
    state: I.ITransactionState,
    { id: transactionId }: I.IApproveTransactionSuccess
  ): I.ITransactionState => {
    const { data, meta } = state.transactions as ITransactionObject
    const { total, offset } = meta

    return {
      ...state,
      isProcessingActiveTransaction: false,
      transactions: {
        data: data.filter(({ id }) => id !== transactionId),
        meta: {
          ...meta,
          total: total - 1,
          offset: !!offset ? offset - 1 : offset,
        },
      },
    }
  },
  [t.DECLINE_TRANSACTION_REQUEST]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isProcessingActiveTransaction: true,
  }),
  [t.DECLINE_TRANSACTION_ERROR]: (
    state: I.ITransactionState
  ): I.ITransactionState => ({
    ...state,
    isProcessingActiveTransaction: false,
  }),
  [t.DECLINE_TRANSACTION_SUCCESS]: (
    state: I.ITransactionState,
    { id: transactionId }: I.IDeclineTransactionSuccess
  ): I.ITransactionState => {
    const { data, meta } = state.transactions as ITransactionObject
    const { total, offset } = meta

    return {
      ...state,
      isProcessingActiveTransaction: false,
      transactions: {
        data: data.filter(({ id }) => id !== transactionId),
        meta: {
          ...meta,
          total: total - 1,
          offset: !!offset ? offset - 1 : offset,
        },
      },
    }
  },
}

export default function reducer(
  state = initialState,
  action: I.TransactionInterfaces
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
